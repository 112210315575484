<template>
    <div class="organization_register_confirm">
        <div class="organization_register_confirm__top">
            <div class="organization_register_confirm__top__step">
                <span>
                    ログイン情報入力
                </span>
                <span>
                    担当者情報登録
                </span>
                <span class="active">
                    登録内容確認
                </span>
                <span>
                    ご利用登録完了
                </span>
            </div>
            <p class="organization_register_confirm__top-title">
                登録内容確認
            </p>
        </div>
        <div class="organization_register_confirm__body">
            <div class="mw-1000">
                <div class="organization_register_confirm__body-message">
                    登録内容をご確認ください
                </div>
                <div class="organization_register_confirm__body__form">
                    <form @submit="onSubmit" class="horizontal">
                        <div class="form-ctrl">
                            <label  class="text">企業名</label>
                            <div
                                class="organization_register_confirm__body__form-confirm"
                            >
                                {{ state.profileForm.name }}
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label  class="text">（企業名）カナ</label>
                            <div
                                class="organization_register_confirm__body__form-confirm"
                            >
                                  {{ state.profileForm.name_kana }}
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label  class="text">メールアドレス</label>
                            <div
                                class="organization_register_confirm__body__form-confirm"
                            >
                                {{ state.profileForm.email }}
                            </div>
                        </div>
                        <div class="form-ctrl">
                            <label  class="text"
                                >所在地</label
                            >
                            <div
                                class="organization_register_confirm__body__form-confirm"
                            >
                                {{
                                    state.profileForm.project_place_prefecture_name +
                                        "  " +
                                        state.profileForm.project_place_city_name
                                }}
                            </div>
                        </div>
                        <div class="form-ctrl">
                          <label  class="text">
                                担当者氏名<br />
                                <span class="text-active-2">※公開されません</span>
                          </label>
                          <div
                              class="organization_register_confirm__body__form-confirm"
                          >
                            {{ state.profileForm.family_name }}　{{ state.profileForm.given_name }}
                          </div>
                        </div>
                        <div class="form-ctrl">
                          <label  class="text">
                                （担当者氏名）カナ<br />
                                <span class="text-active-2">※公開されません</span>
                          </label>
                          <div
                              class="organization_register_confirm__body__form-confirm"
                          >
                            {{ state.profileForm.family_name_kana }}　{{ state.profileForm.given_name_kana }}
                          </div>
                        </div>
                        <div class="form-ctrl">
                            <label  class="text">
                                連絡先電話番号<br />
                                <span class="text-active-2">※公開されません</span>
                            </label>
                            <div
                                class="organization_register_confirm__body__form-confirm"
                            >
                                {{
                                    formatPhoneNumber(
                                        state.profileForm.phone_number
                                    )
                                }}
                            </div>
                        </div>
                        <div class="btn_group">
                            <button
                                type="button"
                                @click="goBack"
                                class="btn-md btn-light-gray shadow"
                            >
                                戻る
                            </button>
                            <button
                                type="submit"
                                :disabled="isSubmitting"
                                :class="{ submitting: isSubmitting }"
                                class="btn-md btn-blue shadow"
                            >
                                <i
                                    v-if="isSubmitting"
                                    class="fas fa-circle-notch fa-spin"
                                ></i
                                >登録完了
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts" src="@/presentation/views/organization/register-invited/confirm/Confirm.ts"></script>

<style lang="scss" scoped>
@import "@/presentation/views/organization/register-invited/confirm/Confirm.scss";
</style>
