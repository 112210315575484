import { reactive, defineComponent, onMounted } from "vue";
import { useRouter } from "vue-router";
import Breadcrumb from "@/presentation/components/breadcrumb/Breadcrumb.vue";
import { useStore } from "vuex";
import RoutePath from "@/commons/RoutePath";
import OrganizationRegisterInteractor from "@/domain/usecases/OrganizationRegisterInteractor";
import DIContainer from "@/core/DIContainer";
import { useForm } from "vee-validate";
import TransformUtil from '@/commons/TransformUtil';
export default defineComponent({
    name: "OrganizationRegisterInvitedConfirm",
    components: {
        Breadcrumb: Breadcrumb
    },
    setup: function () {
        var store = useStore();
        var router = useRouter();
        var organizationRegisterInteractor = DIContainer.instance.get(OrganizationRegisterInteractor);
        var _a = useForm(), isSubmitting = _a.isSubmitting, handleSubmit = _a.handleSubmit;
        var state = reactive({
            listBreadcrumb: [
                {
                    route: '/', name: "TOP"
                },
                {
                    route: '', name: "基本情報入力"
                }
            ],
            profileForm: {}
        });
        var onSubmit = handleSubmit(function () {
            return organizationRegisterInteractor.registerInvited(state.profileForm).then(function (result) {
                router.push(RoutePath.ORGANIZATION_REGISTER_INVITED_COMPLETED);
            });
        });
        function goBack() {
            router.back();
        }
        function hidePassword(values) {
            if (!values) {
                return "";
            }
            var cache = "";
            for (var i = 0; i < values.length; i++) {
                cache += "*";
            }
            return cache;
        }
        function formatPhoneNumber(str) {
            return TransformUtil.formatPhoneNumber(str);
        }
        onMounted(function () {
            var organizationInvitedResgisterData = store.state.organizationInvitedResgisterData;
            if (!organizationInvitedResgisterData.email) {
                return router.push(RoutePath.LOGIN);
            }
            Object.assign(state.profileForm, organizationInvitedResgisterData);
        });
        return {
            state: state,
            isSubmitting: isSubmitting,
            onSubmit: onSubmit,
            goBack: goBack,
            hidePassword: hidePassword,
            formatPhoneNumber: formatPhoneNumber
        };
    }
});
